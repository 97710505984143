import {
  ClientInfoComputedFields,
  FieldValue,
  HouseholdInfo,
  HouseholdInfoPath,
  MgpInfo,
  MgpSectionWithFields,
  MgpSectionWithSubSections,
  MgpSubSection,
} from "./types";
import _ from "lodash";
import moment from "moment";
import { EMPTY_FIELD_PLACEHOLDER } from "./constants";
import {
  CareerStage,
  CareerStageExpanded,
  CareerStageMapping,
} from "@common/constants";
import {
  getPortfolioTypeFromRiskScore,
  getReviewListValue,
  IMPORTANT_RELATIONSHIPS_PERSON_RELATIONSHIP_OPTIONS,
  IMPORTANT_RELATIONSHIP_BASE_DEPENDENT_STATUS_OPTIONS,
  IMPORTANT_RELATIONSHIP_ORGANIZATION_TYPE_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  EMPLOYMENT_TYPE,
  CAREER_CHANGE_OPTIONS,
  ADDITIONAL_INCOME_SOURCE_OPTIONS,
  WILLING_TO_RETIRE_LATER_OPTIONS,
  CONSIDER_PART_TIME_RETIREMENT_OPTIONS,
  GOALS_OPTIONS,
  INTERESTS_OPTIONS,
  ESTATE_PLANNING_OPTIONS,
  INSURANCE_POLICIES_OPTIONS,
} from "@earned/wizard";

const isEmpty = (v: FieldValue) =>
  v === null ||
  v === undefined ||
  v === "" ||
  (Array.isArray(v) && v.length === 0);
const transformUpperSnakeCase = (v: string) =>
  v
    .split("_")
    .map((substr) => _.capitalize(substr.toLowerCase()))
    .join(" ");

const formatters = {
  string: (v: FieldValue) => (isEmpty(v) ? EMPTY_FIELD_PLACEHOLDER : String(v)),
  transform:
    (
      config: { rule: (v: string) => string } = {
        rule: transformUpperSnakeCase,
      }
    ) =>
    (v: FieldValue) =>
      isEmpty(v) ? EMPTY_FIELD_PLACEHOLDER : config.rule(v as string),
  boolean:
    (
      config: { trueLabel: string; falseLabel: string } = {
        trueLabel: "Yes",
        falseLabel: "No",
      }
    ) =>
    (v: FieldValue) =>
      isEmpty(v)
        ? EMPTY_FIELD_PLACEHOLDER
        : (v as boolean)
        ? config.trueLabel
        : config.falseLabel,
  currency:
    (config: { includeCents?: boolean } = { includeCents: false }) =>
    (v: FieldValue) => {
      if (isEmpty(v)) return EMPTY_FIELD_PLACEHOLDER;
      return (v as number).toLocaleString("en-us", {
        currency: "USD",
        style: "currency",
        maximumFractionDigits: config.includeCents ? 2 : 0,
      });
    },
  capitalize: (v: FieldValue) =>
    isEmpty(v) ? EMPTY_FIELD_PLACEHOLDER : _.capitalize(v as string),
  date:
    (config: { mask: string } = { mask: "MM/DD/yyyy" }) =>
    (v: FieldValue) =>
      isEmpty(v) || !moment(v as string).isValid()
        ? EMPTY_FIELD_PLACEHOLDER
        : moment(v as string).format(config.mask),
  ssn:
    (config: { hidden: boolean } = { hidden: true }) =>
    (v: FieldValue) => {
      if (isEmpty(v)) return EMPTY_FIELD_PLACEHOLDER;
      const withHyphens = (v as string)
        .split("")
        .map((c, i) => c + ([2, 4].includes(i) ? "-" : ""))
        .join("");
      if (config.hidden)
        return withHyphens
          .split("")
          .map((c, i) => (c === "-" ? c : i < withHyphens.length - 4 ? "X" : c))
          .join("");
      return withHyphens;
    },
  careerStage: (v: FieldValue) => {
    if (isEmpty(v)) return EMPTY_FIELD_PLACEHOLDER;
    return CareerStageMapping[v as CareerStage | CareerStageExpanded];
  },
  phone: (v: FieldValue) => {
    if (isEmpty(v)) return EMPTY_FIELD_PLACEHOLDER;
    const cleaned = ("" + v).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return String(v);
  },
};

const fieldMappings: Partial<
  Record<
    HouseholdInfoPath,
    {
      label: string;
      formatter: (v: FieldValue) => string;
      clipboardFormatter?: (v: FieldValue) => string;
    }
  >
> = {
  "client.ADDRESS_CITY": { label: "City", formatter: formatters.string },
  "client.ADDRESS_STATE": { label: "State", formatter: formatters.string },
  "client.ADDRESS_STREET": { label: "Street", formatter: formatters.string },
  "client.ADDRESS_STREET_2": { label: "Unit", formatter: formatters.string },
  "client.ADDRESS_ZIP": { label: "Zip Code", formatter: formatters.string },
  "client.ANNUAL_INCOME": {
    label: "Annual Income",
    formatter: formatters.currency(),
  },
  "client.CAREER_STAGE": {
    label: "Career Stage",
    formatter: formatters.careerStage,
  },
  "client.CAREER_STAGE_EXPANDED": {
    label: "Career Stage (Expanded)",
    formatter: formatters.careerStage,
  },
  "client.CLIENT_ID": { label: "Client ID", formatter: formatters.string },
  "client.DOB": { label: "Date of Birth", formatter: formatters.date() },
  "client.DRIVER_LICENSE_EXP": {
    label: "Driver's License Expiration Date",
    formatter: formatters.date(),
  },
  "client.DRIVER_LICENSE_NUMBER": {
    label: "Driver's License Number",
    formatter: formatters.string,
  },
  "client.DRIVER_LICENSE_STATE": {
    label: "Driver's License State",
    formatter: formatters.string,
  },
  "client.EMAIL": { label: "Email", formatter: formatters.string },
  "client.EMPLOYER_NAME": { label: "Employer", formatter: formatters.string },
  "client.EMPLOYER_ADDRESS_STREET": {
    label: "Employer Address Street",
    formatter: formatters.string,
  },
  "client.EMPLOYER_ADDRESS_STREET_2": {
    label: "Employer Address Unit",
    formatter: formatters.string,
  },
  "client.EMPLOYER_ADDRESS_CITY": {
    label: "Employer Address City",
    formatter: formatters.string,
  },
  "client.EMPLOYER_ADDRESS_STATE": {
    label: "Employer Address State",
    formatter: formatters.string,
  },
  "client.EMPLOYER_ADDRESS_ZIP": {
    label: "Employer Address Zip Code",
    formatter: formatters.string,
  },
  "client.EMPLOYMENT_STATUS": {
    label: "Employment Status",
    formatter: formatters.string,
  },
  "client.FIRST_NAME": { label: "First Name", formatter: formatters.string },
  "client.GENDER": { label: "Gender", formatter: formatters.capitalize },
  "client.HAS_FINRA_ASSOCIATION": {
    label: "Has FINRA Association",
    formatter: formatters.boolean(),
  },
  "client.HOUSEHOLD_ID": {
    label: "Household ID",
    formatter: formatters.string,
  },
  "client.IS_PRIMARY_CLIENT": {
    label: "Primary Client",
    formatter: formatters.boolean(),
  },
  "client.LAST_NAME": { label: "Last Name", formatter: formatters.string },
  "client.MAILING_ADDRESS_CITY": {
    label: "Mailing Address City",
    formatter: formatters.string,
  },
  "client.MAILING_ADDRESS_STATE": {
    label: "Mailing Address State",
    formatter: formatters.string,
  },
  "client.MAILING_ADDRESS_STREET": {
    label: "Mailing Address Street",
    formatter: formatters.string,
  },
  "client.MAILING_ADDRESS_STREET_2": {
    label: "Mailing Address Unit",
    formatter: formatters.string,
  },
  "client.MAILING_ADDRESS_ZIP": {
    label: "Mailing Address Zip Code",
    formatter: formatters.string,
  },
  "client.NPI_NUMBER": { label: "NPI Number", formatter: formatters.string },
  "client.OCCUPATION": { label: "Occupation", formatter: formatters.string },
  "client.SOURCE_INCOME": {
    label: "Source of Income",
    formatter: formatters.string,
  },
  "client.TOTAL_ASSETS": {
    label: "Total Assets",
    formatter: formatters.currency(),
  },
  "client.PHONE_NUMBER": { label: "Phone", formatter: formatters.phone },
  "client.SPECIALTY": { label: "Specialty", formatter: formatters.string },
  "client.SSN": {
    label: "Social Security #",
    formatter: formatters.ssn(),
    clipboardFormatter: formatters.ssn({ hidden: false }),
  },
  "client.SSN_LAST_FOUR_DIGITS": {
    label: "Social Security # (Last Four)",
    formatter: formatters.string,
  },
  "client.TOBACCO_USE": {
    label: "Uses Tobacco",
    formatter: formatters.boolean(),
  },
  "client.YEARS_PRACTICE": {
    label: "Years Practicing",
    formatter: formatters.string,
  },
  "client.RTQ_EXPECTED_MONEY_NEED_PERIOD": {
    label: "Expected Money Need Period",
    formatter: formatters.transform(),
  },
  "client.RTQ_COVID_19_INVESTMENT_STRATEGY": {
    label: "COVID-19 Investment Strategy",
    formatter: formatters.transform(),
  },
  "client.RTQ_INCOME_PROJECTION": {
    label: "Income Projection",
    formatter: formatters.transform(),
  },
  "client.RTQ_INCOME_PROTECTION_STATUS": {
    label: "Income Projection Status",
    formatter: formatters.transform(),
  },
  "client.RTQ_INVESTMENT_CHECK_FREQUENCY": {
    label: "Investment Check Frequency",
    formatter: formatters.transform(),
  },
  "client.RTQ_INVESTMENT_GOAL": {
    label: "Investment Goal",
    formatter: formatters.transform(),
  },
  "client.RTQ_INVESTMENT_KNOWLEDGE": {
    label: "Investment Knowledge",
    formatter: formatters.transform(),
  },
  "client.RTQ_INVESTMENT_PORTFOLIO_CHOICE": {
    label: "Portfolio Choice",
    formatter: formatters.transform(),
  },
  "client.RTQ_FINANCIAL_EMERGENCY_ABILITY": {
    label: "Ability to Handle Financial Emergency",
    formatter: formatters.transform(),
  },
  "client.RTQ_SCORE": {
    label: "Risk Tolerance Score",
    formatter: formatters.string,
  },
  "client.RTQ_TARGET_PORTFOLIO_TYPE": {
    label: "Target Portfolio Type",
    formatter: formatters.string,
  },
  "client.BIRTH_STATE": { label: "Birth State", formatter: formatters.string },
  "client.BORN_IN_THE_USA": {
    label: "Born in the USA",
    formatter: formatters.boolean(),
  },
  "client.NET_WORTH": { label: "Net Worth", formatter: formatters.currency() },
  "client.CURR_LIFE_POLICY_TYPE": {
    label: "Current Life Insurance Policy Type",
    formatter: formatters.transform(),
  },
  "client.CURR_LIFE_POLICY_MONTHLY_PREM": {
    label: "Current Life Insurance Monthly Premium",
    formatter: formatters.currency(),
  },
  "client.ABOUT_US_REFERRAL": {
    label: "About Us (Referral)",
    formatter: formatters.transform(),
  },
  "client.INTERESTS": {
    label: "Financial Interests",
    formatter: (v: FieldValue) =>
      (v as string[] | undefined)
        ?.map((interest) =>
          formatters.string(getReviewListValue(INTERESTS_OPTIONS, interest))
        )
        .join(", ") ?? EMPTY_FIELD_PLACEHOLDER,
  },
  "client.EULA_ACCEPT_TIMESTAMP": {
    label: "Accepted EULA Terms Timestamp",
    formatter: formatters.date({ mask: "LLL" }),
  },
  "client.PLACE_OF_BIRTH": {
    label: "Place of Birth",
    formatter: formatters.string,
  },
  "client.NOT_A_DOCTOR": {
    label: "Not a Doctor",
    formatter: formatters.boolean(),
  },
};

const computedFields: {
  client: (householdInfo: HouseholdInfo) => ClientInfoComputedFields["client"];
  coClient?: (
    householdInfo: HouseholdInfo
  ) => ClientInfoComputedFields["coClient"];
} = {
  client: (householdInfo) => ({
    RTQ_TARGET_PORTFOLIO_TYPE:
      householdInfo.client?.RTQ_SCORE !== undefined
        ? getPortfolioTypeFromRiskScore(householdInfo.client.RTQ_SCORE)
        : undefined,
  }),
  coClient: (householdInfo) => ({
    RTQ_TARGET_PORTFOLIO_TYPE:
      householdInfo.coClient?.RTQ_SCORE !== undefined
        ? getPortfolioTypeFromRiskScore(householdInfo.coClient.RTQ_SCORE)
        : undefined,
  }),
};

const buildImportantRelationshipFields = (
  data: HouseholdInfo
): MgpSubSection[] => {
  return (
    data.household.importantRelationships?.map((relationship) => {
      if (relationship.TYPE === "PERSON") {
        return {
          fields: [
            {
              label: "Name",
              value: `${relationship.FIRST_NAME} ${relationship.LAST_NAME}`,
              formatter: () =>
                formatters.string(
                  getReviewListValue(
                    IMPORTANT_RELATIONSHIPS_PERSON_RELATIONSHIP_OPTIONS,
                    relationship.FIRST_NAME
                  )
                ),
            },
            {
              label: "Relationship",
              value: relationship.RELATIONSHIP,
              formatter: () =>
                formatters.string(
                  getReviewListValue(
                    IMPORTANT_RELATIONSHIPS_PERSON_RELATIONSHIP_OPTIONS,
                    relationship.RELATIONSHIP
                  )
                ),
            },
            {
              label: "Date of Birth",
              value: relationship.DOB,
              formatter: formatters.date(),
            },
            {
              label: "Dependent Status",
              value: relationship.DEPENDENT_STATUS,
              formatter: () =>
                formatters.string(
                  getReviewListValue(
                    IMPORTANT_RELATIONSHIP_BASE_DEPENDENT_STATUS_OPTIONS,
                    relationship.DEPENDENT_STATUS
                  )
                ),
            },
          ],
        };
      }

      return {
        fields: [
          {
            label: "Organization Name",
            value: "NAME" in relationship ? relationship.NAME : "",
            formatter: () =>
              formatters.string(
                getReviewListValue(
                  IMPORTANT_RELATIONSHIP_ORGANIZATION_TYPE_OPTIONS,
                  ("NAME" in relationship ? relationship.NAME : "") as string
                )
              ),
          },
          {
            label: "Organization Type",
            value:
              "ORGANIZATION_TYPE" in relationship
                ? relationship.ORGANIZATION_TYPE
                : "",
            formatter: () =>
              formatters.string(
                getReviewListValue(
                  IMPORTANT_RELATIONSHIP_ORGANIZATION_TYPE_OPTIONS,
                  ("ORGANIZATION_TYPE" in relationship
                    ? relationship.ORGANIZATION_TYPE
                    : "") as string
                )
              ),
          },
        ],
      };
    }) ?? []
  );
};

const buildEmploymentFields = (
  data: HouseholdInfo,
  hasCoClient: boolean
): MgpSectionWithSubSections[] => {
  const employments = _.groupBy(data.household.employments, "CLIENT_ID");
  const clients: Record<
    string,
    HouseholdInfo["client"] | HouseholdInfo["coClient"]
  > = {
    [data.client.CLIENT_ID]: data.client,
    [data.coClient?.CLIENT_ID]: data.coClient,
  };
  return Object.entries(employments).map(([clientId, employments]) => {
    return {
      name: hasCoClient
        ? `${clients[clientId].FIRST_NAME} ${clients[clientId].LAST_NAME}`
        : undefined,
      subSections: employments.map((employment) => {
        return {
          fields: [
            {
              label: "Employment Title",
              value: employment.TITLE,
              formatter: formatters.string,
            },
            {
              label: "Employer Name",
              value: employment.EMPLOYER_NAME,
              formatter: formatters.string,
            },
            {
              label: "Employment Income",
              value: employment.ANNUAL_INCOME,
              formatter: formatters.currency(),
            },
            {
              label: "Employment Type",
              value:
                employment.TYPE == EMPLOYMENT_TYPE.OTHER
                  ? employment.OTHER_TYPE
                  : employment.TYPE,
              formatter: () =>
                formatters.string(
                  getReviewListValue(
                    EMPLOYMENT_TYPE_OPTIONS,
                    employment.TYPE || employment.OTHER_TYPE
                  )
                ),
            },
          ],
        };
      }),
    };
  });
};

const buildCareerChangeFields = (
  data: HouseholdInfo,
  hasCoClient: boolean
): MgpSectionWithFields[] => {
  const { client, coClient } = data;
  const careerChange = [
    {
      name: hasCoClient
        ? `${client.FIRST_NAME} ${client.LAST_NAME}`
        : undefined,
      fields: client.CAREER_CHANGE?.map((careerChange) => {
        return {
          label: getReviewListValue(
            [...CAREER_CHANGE_OPTIONS],
            careerChange
          ) as string,
          value: client[`CAREER_CHANGE_${careerChange}` as keyof typeof client],
          formatter: formatters.string,
        };
      }),
    },
  ];

  if (hasCoClient) {
    careerChange.push({
      name: `${coClient.FIRST_NAME} ${coClient.LAST_NAME}`,
      fields: coClient.CAREER_CHANGE?.map((careerChange: string) => {
        return {
          label: getReviewListValue([...CAREER_CHANGE_OPTIONS], careerChange),
          value:
            coClient[`CAREER_CHANGE_${careerChange}` as keyof typeof coClient],
          formatter: formatters.string,
        };
      }),
    });
  }
  return careerChange;
};

const buildAdditionalIncomeFields = (
  data: HouseholdInfo,
  hasCoClient: boolean
): MgpSubSection[] => {
  const { household } = data;
  return household.retirementIncomes.map((income) => {
    const fields = [];

    if (hasCoClient) {
      fields.push({
        label: "Client Name",
        value: income.OWNER_NAME,
        formatter: formatters.string,
      });
    }

    return {
      fields: [
        ...fields,
        {
          label: "Income Source",
          value: income.INCOME_SOURCE,
          formatter: () =>
            formatters.string(
              getReviewListValue(
                ADDITIONAL_INCOME_SOURCE_OPTIONS,
                income.INCOME_SOURCE
              )
            ),
        },
        {
          label: "Income Source Description",
          value: income.DESCRIPTION,
          formatter: formatters.string,
        },
        {
          label: "Average Monthly Income",
          value: income.MONTHLY_INCOME,
          formatter: formatters.currency(),
        },
        {
          label: "Start Year",
          value: income.START_YEAR,
          formatter: formatters.string,
        },
        {
          label: "End Year",
          value: income.END_YEAR,
          formatter: formatters.string,
        },
      ],
    };
  });
};

const buildRetirementAgeFields = (
  data: HouseholdInfo,
  hasCoClient: boolean
): MgpSubSection[] => {
  const { client, coClient } = data;

  const retirementAge = [
    {
      name: hasCoClient
        ? `${client.FIRST_NAME} ${client.LAST_NAME}`
        : undefined,
      fields: [
        {
          label: "Target Retirement Age",
          value: client.TARGET_RETIREMENT_AGE,
          formatter: formatters.string,
        },
        {
          label: "How willing are you to retire later?",
          value: client.WILLING_TO_RETIRE_LATER,
          formatter: () =>
            formatters.string(
              getReviewListValue(
                WILLING_TO_RETIRE_LATER_OPTIONS,
                client.WILLING_TO_RETIRE_LATER
              )
            ),
        },
        {
          label: "Are you considering part-time work before full retirement?",
          value: client.CONSIDER_PART_TIME_RETIREMENT,
          formatter: () =>
            formatters.string(
              getReviewListValue(
                CONSIDER_PART_TIME_RETIREMENT_OPTIONS,
                client.CONSIDER_PART_TIME_RETIREMENT
              )
            ),
        },
      ],
    },
  ];

  if (hasCoClient) {
    retirementAge.push({
      name: `${coClient.FIRST_NAME} ${coClient.LAST_NAME}`,
      fields: [
        {
          label: "Target Retirement Age",
          value: coClient.TARGET_RETIREMENT_AGE,
          formatter: formatters.string,
        },
        {
          label: "How willing are you to retire later?",
          value: coClient.WILLING_TO_RETIRE_LATER,
          formatter: () =>
            formatters.string(
              getReviewListValue(
                WILLING_TO_RETIRE_LATER_OPTIONS,
                client.WILLING_TO_RETIRE_LATER
              )
            ),
        },
        {
          label: "Are you considering part-time work before full retirement?",
          value: coClient.CONSIDER_PART_TIME_RETIREMENT,
          formatter: () =>
            formatters.string(
              getReviewListValue(
                CONSIDER_PART_TIME_RETIREMENT_OPTIONS,
                client.CONSIDER_PART_TIME_RETIREMENT
              )
            ),
        },
      ],
    });
  }
  return retirementAge;
};

const buildProtectionAndLegacyPlanningFields = (
  data: HouseholdInfo,
  hasCoClient: boolean
): MgpSubSection[] => {
  const { client, coClient } = data;
  const protectionAndLegacyPlanning = [
    {
      name: hasCoClient
        ? `${client.FIRST_NAME} ${client.LAST_NAME}`
        : undefined,
      fields: client.CURRENT_INSURANCE_POLICIES?.map((insurancePolicy) => {
        return {
          label: "Individual Insurance Policies",
          value: insurancePolicy,
          formatter: () =>
            formatters.string(
              getReviewListValue(INSURANCE_POLICIES_OPTIONS, insurancePolicy)
            ),
        };
      }).concat(
        client.CURRENT_ESTATE_PLANNING?.map((estatePlanning) => {
          return {
            label: "Estate Planning",
            value: estatePlanning,
            formatter: () =>
              formatters.string(
                getReviewListValue(ESTATE_PLANNING_OPTIONS, estatePlanning)
              ),
          };
        })
      ),
    },
  ];

  if (hasCoClient) {
    protectionAndLegacyPlanning.push({
      name: `${coClient.FIRST_NAME} ${coClient.LAST_NAME}`,
      fields: coClient.CURRENT_INSURANCE_POLICIES?.map(
        (insurancePolicy: string) => {
          return {
            label: "Individual Insurance Policies",
            value: insurancePolicy,
            formatter: () =>
              formatters.string(
                getReviewListValue(INSURANCE_POLICIES_OPTIONS, insurancePolicy)
              ),
          };
        }
      ).concat(
        coClient.CURRENT_ESTATE_PLANNING?.map((estatePlanning: string) => {
          return {
            label: "Estate Planning",
            value: estatePlanning,
            formatter: () =>
              formatters.string(
                getReviewListValue(ESTATE_PLANNING_OPTIONS, estatePlanning)
              ),
          };
        })
      ),
    });
  }

  return protectionAndLegacyPlanning;
};

const getMgpInfo = (data: HouseholdInfo | undefined): MgpInfo => {
  if (!data) return [];
  const { client, coClient, household } = data;
  const hasCoClient = !isEmpty(coClient) && coClient?.FIRST_NAME;
  const clientName = `${client.FIRST_NAME} ${client.LAST_NAME}`;

  const information = {
    name: "Information",
    fields: [
      {
        label: `${hasCoClient ? `${clientName}'s ` : ""}Annual Income`,
        value: client.ANNUAL_INCOME,
        formatter: formatters.currency(),
      },
    ],
  };

  const goals = household.goals.map((goal) => {
    const isCustomGoal = goal.TYPE === "CUSTOM";
    return {
      label: isCustomGoal ? "Other Goal Description" : "Goal",
      value: isCustomGoal ? `Other (${goal.CUSTOM_TYPE})` : goal.TYPE,
      formatter: () =>
        formatters.string(
          getReviewListValue(
            GOALS_OPTIONS,
            isCustomGoal ? `Other (${goal.CUSTOM_TYPE})` : goal.TYPE
          )
        ),
    };
  });

  const retirementExpenses = [
    {
      label: "Current Monthly Expenses",
      value: household.CURRENT_MONTHLY_LIVING_EXPENSES,
      formatter: formatters.currency(),
    },
    {
      label: "Retirement Monthly Expenses",
      value: household.RETIREMENT_MONTHLY_LIVING_EXPENSES,
      formatter: formatters.currency(),
    },
  ];

  if (hasCoClient) {
    const coClientName = `${coClient.FIRST_NAME} ${coClient.LAST_NAME}`;

    information.fields.push({
      label: `${coClientName}'s Annual Income`,
      value: coClient.ANNUAL_INCOME,
      formatter: formatters.currency(),
    });
  }

  const extraSavings = [
    {
      label: "Extra Yearly Savings",
      value: household.WILLING_TO_SAVE_EXTRA_ANNUAL_AMOUNT,
      formatter: formatters.currency(),
    },
    {
      label: "How willing are you to save more?",
      value: household.WILLING_TO_SAVE_EXTRA,
      formatter: () =>
        formatters.string(
          getReviewListValue(
            WILLING_TO_RETIRE_LATER_OPTIONS,
            household.WILLING_TO_SAVE_EXTRA
          )
        ),
    },
  ];
  return [
    information,
    {
      name: "Important Relationships",
      subSections: buildImportantRelationshipFields(data),
    },
    {
      name: "Employment",
      subSections: buildEmploymentFields(data, hasCoClient),
    },
    {
      name: "Career Change",
      subSections: buildCareerChangeFields(data, hasCoClient),
    },
    {
      name: "Additional Income",
      subSections: buildAdditionalIncomeFields(data, hasCoClient),
    },
    {
      name: "Goals",
      fields: goals,
    },
    {
      name: "Retirement Expenses",
      fields: retirementExpenses,
    },
    {
      name: "Retirement Age",
      subSections: buildRetirementAgeFields(data, hasCoClient),
    },
    { name: "Extra Savings", fields: extraSavings },
    {
      name: "Protection & Legacy Planning",
      subSections: buildProtectionAndLegacyPlanningFields(data, hasCoClient),
    },
  ];
};

function getNestedValue(obj: HouseholdInfo, path: HouseholdInfoPath) {
  return path.split(".").reduce((current, key) => {
    return current && current[key] !== undefined ? current[key] : undefined;
  }, obj as any);
}

const getFieldMapping = (path: HouseholdInfoPath, isCoClient = false) => {
  const adjustedPath = isCoClient
    ? (path.replace("client.", "coClient.") as HouseholdInfoPath)
    : path;
  const baseField = path.replace("coClient.", "client.") as HouseholdInfoPath;
  return fieldMappings[adjustedPath] || fieldMappings[baseField];
};

export {
  formatters,
  fieldMappings,
  computedFields,
  isEmpty,
  getMgpInfo,
  getNestedValue,
  getFieldMapping,
};
