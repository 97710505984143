import { TabOption } from "@earned/core";
import {
  ClientInfoTabId,
  HouseholdInfoTabId,
  HouseholdInfoPath,
} from "./types";
import { prefixFields } from "./utils";

const CLIENT_INFO_TAB_OPTIONS: TabOption<ClientInfoTabId>[] = [
  {
    id: ClientInfoTabId.All,
    label: "All",
  },
  {
    id: ClientInfoTabId.Profile,
    label: "Profile",
  },
  {
    id: ClientInfoTabId.DisabilityInsurance,
    label: "Disability Insurance",
  },
  {
    id: ClientInfoTabId.TermLifeInsurance,
    label: "Term Life Insurance",
  },
  {
    id: ClientInfoTabId.RiskTolerance,
    label: "Risk Tolerance",
  },
];

const HOUSEHOLD_INFO_TAB_OPTIONS: TabOption<HouseholdInfoTabId>[] = [
  {
    id: HouseholdInfoTabId.All,
    label: "All",
  },
  {
    id: HouseholdInfoTabId.SignUp,
    label: "Sign Up",
  },
  {
    id: HouseholdInfoTabId.Profile,
    label: "Profile",
  },
  {
    id: HouseholdInfoTabId.DisabilityInsurance,
    label: "Disability",
  },
  {
    id: HouseholdInfoTabId.TermLifeInsurance,
    label: "Term Life",
  },
  {
    id: HouseholdInfoTabId.RiskTolerance,
    label: "RTQ",
  },
  {
    id: HouseholdInfoTabId.MoneyGuidePro,
    label: "MGP",
  },
];

const PROFILE_BASE_FIELDS = [
  "FIRST_NAME",
  "LAST_NAME",
  "EMAIL",
  "PHONE_NUMBER",
  "SPECIALTY",
  "CAREER_STAGE",
  "ADDRESS_STREET",
  "ADDRESS_STREET_2",
  "ADDRESS_CITY",
  "ADDRESS_STATE",
  "ADDRESS_ZIP",
  "MAILING_ADDRESS_STREET",
  "MAILING_ADDRESS_STREET_2",
  "MAILING_ADDRESS_CITY",
  "MAILING_ADDRESS_STATE",
  "MAILING_ADDRESS_ZIP",
  "SSN",
  "SSN_LAST_FOUR_DIGITS",
  "DOB",
  "EMPLOYMENT_STATUS",
  "EMPLOYER_NAME",
  "OCCUPATION",
  "NPI_NUMBER",
  "DRIVER_LICENSE_STATE",
  "DRIVER_LICENSE_NUMBER",
  "DRIVER_LICENSE_EXP",
  "ANNUAL_INCOME",
  "HAS_FINRA_ASSOCIATION",
] as const;

const DISABILITY_BASE_FIELDS = [
  "FIRST_NAME",
  "LAST_NAME",
  "DOB",
  "ADDRESS_STATE",
  "SPECIALTY",
  "GENDER",
  "ANNUAL_INCOME",
  "YEARS_PRACTICE",
  "TOBACCO_USE",
  "BIRTH_STATE",
  "PLACE_OF_BIRTH",
  "BORN_IN_THE_USA",
] as const;

const RISK_TOLERANCE_BASE_FIELDS = [
  "CAREER_STAGE",
  "CAREER_STAGE_EXPANDED",
  "RTQ_EXPECTED_MONEY_NEED_PERIOD",
  "RTQ_INCOME_PROJECTION",
  "RTQ_FINANCIAL_EMERGENCY_ABILITY",
  "RTQ_INCOME_PROTECTION_STATUS",
  "RTQ_INVESTMENT_KNOWLEDGE",
  "RTQ_INVESTMENT_GOAL",
  "RTQ_COVID_19_INVESTMENT_STRATEGY",
  "RTQ_INVESTMENT_CHECK_FREQUENCY",
  "RTQ_INVESTMENT_PORTFOLIO_CHOICE",
  "RTQ_SCORE",
  "RTQ_TARGET_PORTFOLIO_TYPE",
] as const;

const TERM_LIFE_BASE_FIELDS = [
  "FIRST_NAME",
  "LAST_NAME",
  "DOB",
  "ADDRESS_STATE",
  "BIRTH_STATE",
  "PLACE_OF_BIRTH",
  "BORN_IN_THE_USA",
  "ANNUAL_INCOME",
  "NET_WORTH",
  "CURR_LIFE_POLICY_TYPE",
  "CURR_LIFE_POLICY_MONTHLY_PREM",
] as const;

const SIGNUP_INFO_BASE_FIELDS = [
  "FIRST_NAME",
  "LAST_NAME",
  "NPI_NUMBER",
  "CAREER_STAGE",
  "NOT_A_DOCTOR",
  "ABOUT_US_REFERRAL",
  "INTERESTS",
] as const;

const PROFILE_HOUSEHOLD_INFO_FIELDS: HouseholdInfoPath[] = [
  ...prefixFields(PROFILE_BASE_FIELDS, "client"),
  ...prefixFields(PROFILE_BASE_FIELDS, "coClient"),
];

const SIGNUP_INFO_FIELDS: HouseholdInfoPath[] = [
  ...prefixFields(SIGNUP_INFO_BASE_FIELDS, "client"),
  ...prefixFields(SIGNUP_INFO_BASE_FIELDS, "coClient"),
];

const DISABILITY_INSURANCE_HOUSEHOLD_INFO_FIELDS: HouseholdInfoPath[] = [
  ...prefixFields(DISABILITY_BASE_FIELDS, "client"),
  ...prefixFields(DISABILITY_BASE_FIELDS, "coClient"),
];

const RISK_TOLERANCE_HOUSEHOLD_INFO_FIELDS: HouseholdInfoPath[] = [
  ...prefixFields(RISK_TOLERANCE_BASE_FIELDS, "client"),
  ...prefixFields(RISK_TOLERANCE_BASE_FIELDS, "coClient"),
];

const TERM_LIFE_INSURANCE_HOUSEHOLD_INFO_FIELDS: HouseholdInfoPath[] = [
  ...prefixFields(TERM_LIFE_BASE_FIELDS, "client"),
  ...prefixFields(TERM_LIFE_BASE_FIELDS, "coClient"),
];

const ALL_FIELDS = [
  ...PROFILE_HOUSEHOLD_INFO_FIELDS,
  ...SIGNUP_INFO_FIELDS,
  ...DISABILITY_INSURANCE_HOUSEHOLD_INFO_FIELDS,
  ...RISK_TOLERANCE_HOUSEHOLD_INFO_FIELDS,
  ...TERM_LIFE_INSURANCE_HOUSEHOLD_INFO_FIELDS,
];

const EMPTY_FIELD_PLACEHOLDER = "--";

export {
  EMPTY_FIELD_PLACEHOLDER,
  CLIENT_INFO_TAB_OPTIONS,
  PROFILE_HOUSEHOLD_INFO_FIELDS,
  DISABILITY_INSURANCE_HOUSEHOLD_INFO_FIELDS,
  RISK_TOLERANCE_HOUSEHOLD_INFO_FIELDS,
  TERM_LIFE_INSURANCE_HOUSEHOLD_INFO_FIELDS,
  SIGNUP_INFO_FIELDS,
  HOUSEHOLD_INFO_TAB_OPTIONS,
  ALL_FIELDS,
};
