import createVanilla, { StateCreator } from "zustand/vanilla";
import create from "zustand";
import { persist, PersistOptions } from "zustand/middleware";

interface AdvisorState {
  impersonateClientUserId: string | null;
  setImpersonateClientUserId: (impersonateClientUserId: string) => void;
  isOpenMutateHouseholdModal: boolean;
  setIsOpenMutateHouseholdModal: (isOpen: boolean) => void;
  isOpenAssignTasksToProductModal: boolean;
  setIsOpenAssignTasksToProductModal: (isOpen: boolean) => void;
  reset: () => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
  isOpenHouseholdInfoModal: boolean;
  setIsOpenHouseholdInfoModal: (isOpen: boolean) => void;
}

type AdvisorPersist = (
  config: StateCreator<AdvisorState>,
  options: PersistOptions<AdvisorState>
) => StateCreator<AdvisorState>;

const initialState = {
  isOpenMutateHouseholdModal: false,
  impersonateClientUserId: null,
  isOpenAssignTasksToProductModal: false,
  isOpenHouseholdInfoModal: false,
  searchText: "",
};

const isReactNative =
  typeof navigator !== "undefined" && navigator.product === "ReactNative";

let AsyncStorage: any;

if (isReactNative) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const asyncStorage = require("@react-native-async-storage/async-storage");
  AsyncStorage = asyncStorage.default;
}

const store = createVanilla<AdvisorState>(
  (persist as unknown as AdvisorPersist)(
    (set) => ({
      ...initialState,
      setIsOpenAssignTasksToProductModal(isOpen) {
        set({ isOpenAssignTasksToProductModal: isOpen });
      },
      setIsOpenMutateHouseholdModal: (isOpen) =>
        set({
          isOpenMutateHouseholdModal: isOpen,
        }),
      setImpersonateClientUserId: (userId) =>
        set({ impersonateClientUserId: userId }),
      setSearchText: (searchText) => set({ searchText }),
      setIsOpenHouseholdInfoModal: (isOpen) =>
        set({ isOpenHouseholdInfoModal: isOpen }),
      reset: () => set(initialState),
    }),
    {
      name: "advisor-state-v1",
      getStorage: () => (isReactNative ? AsyncStorage : localStorage),
    }
  )
);

export { store };
export default create(store);
